import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import * as REASON from "store/constants/support";
import Layout from "components/Layout/Layout";
import { GENERAL_INQUIRY } from "store/constants/support";
import SupportLandingSection from "components/SupportLanding/SupportLandingSection";
import ContactUsContents from "components/ContactUsContents/ContactUsContents";
import FaqForm from "components/FaqForm/FaqForm";
import ButtonNav from "components/Design/Button/NavButton";
import Icon from "components/CommonIcon/Icon";
import PageContext from "contexts/PageContext";
import { AYANA_BALI } from "store/constants/hotel";
import ReachUs from "components/ReachUs/ReachUs";
import { useI18next } from "gatsby-plugin-react-i18next";
import { generateSupportURL } from "utils/generateSupportURL";
import { AYANA_BALI_WHATSAPP_LINK } from "store/constants/socmedLink";
import { WECHAT_LINK } from "store/constants/link";
import { transformObject } from "utils/transformv4";

const BaliFaq = ({ data, location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [tabActive, setTabActive] = useState(
    location.hash || location.state?.tabActive
  );

  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    hotelsByLocale: [hotel],
    sections: [destinations],
    pagesByLocale: [{ seo_tags }],
    supportDrawer: [supportDrawer],
    hotelPropertyBali,
  } = transformObject(cms);

  const dataContact = {
    label: t("Contact Us"),
    url: generateSupportURL("bali", GENERAL_INQUIRY),
  };

  const supportDrawerData = {
    ...supportDrawer,
    hotels: hotelPropertyBali,
    hotel: hotel,
  };

  const faqForJapanese = hotel.faqs.filter((faq) => faq.japanese_only);
  const faqOutsideJapanese = hotel.faqs.filter((faq) => !faq.japanese_only);

  let faqs = [];
  if (language === "ja") {
    hotel.faqs.map((faq) => {
      const japanaseFaq = faqForJapanese.find(
        (japaneseFaq) => japaneseFaq.name === faq.name
      );
      if (!faq.japanese_only) {
        if (japanaseFaq) {
          faqs.push(japanaseFaq);
        } else {
          faqs.push(faq);
        }
      }
    });
  } else {
    faqs = faqOutsideJapanese;
  }

  const reachUsLink =
    language === "zh" ? WECHAT_LINK : AYANA_BALI_WHATSAPP_LINK;

  return (
    <PageContext.Provider
      value={{
        showBannerPadding: true,
        location: AYANA_BALI,
        tabActive: tabActive,
      }}>
      <Layout
        whatsAppLink={AYANA_BALI_WHATSAPP_LINK}
        seoTags={seo_tags}
        navigations={navigations}
        hotel={{ ...hotel, ...hotel.destinations[0] }}
        banner={true}
        showBanner={true}
        destinationCards={destinations.images}
        contactOnMobile={dataContact}
        contactOnDesktop={dataContact}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Bali")}
        supportDrawer={supportDrawerData}
        hotelPropertyBali={hotelPropertyBali}>
        <SupportLandingSection
          title={t("Ayana Support")}
          contactUsContents={<ContactUsContents hotels={hotel.offices} />}
          otherContents={
            <>
              <ReachUs supportReachUsLink={reachUsLink} />
              <ButtonNav
                href={generateSupportURL("bali", REASON.GENERAL_INQUIRY)}
                prefixIcon={<Icon name="comment" />}
                label={t("Contact Us")}
              />
            </>
          }
          supportForm={
            <FaqForm
              faq={faqs}
              tabActive={tabActive}
              setTabActive={setTabActive}
            />
          }
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query BaliFaq($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      ...LocalNavigation
    }

    allSocialMediaJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }

    cms {
      sections(filters: { name: { eq: "Bali_Footer_Destination" } }) {
        ...NavSections
      }

      supportDrawer: sections(
        filters: { name: { eq: "Bali_Support_Drawer" } }
      ) {
        ...NavSections
      }

      hotelsByLocale(language: $language, where: { hotel_code: "ARSB" }) {
        data {
          id
          attributes {
            ...NavHotel
            faqs {
              ...CmsFaqsQuestionAnswers_v4
            }
          }
        }
      }

      pagesByLocale(language: $language, where: { name: "Bali_FAQ" }) {
        data {
          id
          attributes {
            ...CmsPageSeoTags_v4
          }
        }
      }

      hotelPropertyBali: hotelsByLocale(
        language: $language
        where: { id: [1, 2, 3, 9] }
        sort: "id:DESC"
      ) {
        data {
          id
          attributes {
            name
            title
            logo {
              ...CmsUploadFileEntityResponse
            }
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
          }
        }
      }
    }
  }
`;
export default BaliFaq;
